<template>
  <layout title="Permissões" :options="options" :rotas="rotas">
    <v-sheet
      :dark="$theme.dark"
      :light="$theme.light"
      class="expande-horizontal sheet-container pt-2"
    >
      <v-flex xs12 class="pr-1" lg3>
        <Filters :filter="filter" />
        <List :filtereds="filtereds" />
        <EmptyList
          :iniciarCadastro="iniciarCadastro"
          v-if="filtereds.length === 0"
        />
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
        <ViewForm v-if="get_permission.new || get_permission._id" />
      </v-flex>
      <v-dialog
        v-else
        transition="slide-x-transition"
        fullscreen
        :value="get_permission._id || get_permission.new ? true : false"
      >
        <v-card>
          <ViewForm />
        </v-card>
      </v-dialog>
    </v-sheet>
  </layout>
</template>

<script>
import EmptyList from "../components/EmptyList.vue";
import Filters from "../components/Filters.vue";
import List from "../components/List.vue";
import ViewForm from "../components/ViewForm.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      filter: {},
      options: [
        {
          nome: "Criar permissão",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
      ],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Permissões",
          path: "/permissions"
        }
      ]
    };
  },
  components: {
    EmptyList,
    Filters,
    List,
    ViewForm
  },
  computed: {
    ...mapGetters([
      "get_permissions_filtros",
      "getLoggedUser",
      "get_permission",
      "get_permissions",
      "getPersonalizedLoading"
    ]),
    filtereds() {
      if (this.filter.search) {
        return this.get_permissions.docs.filter(item => {
          return (
            (item.nome &&
              item.nome
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.email &&
              item.email
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.telefone &&
              item.telefone.includes(this.filter.search.toLowerCase()))
          );
        });
      } else {
        return this.get_permissions.docs;
      }
    }
  },
  methods: {
    excluir_permission_dialog(item) {
      let text = "Deseja remover este permission?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_permission",
        action_value: item
      });
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "list_permissions",
      "criar_estoque",
      "listar_permissioncategorias",
      "criar_permission",
      "listar_estoques",
      "atualizar_permission",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    criarOuAtualizar() {
      if (this.get_permission._id) {
        this.atualizar_permission();
      } else {
        this.criar_permission();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_permission", { new: true, permission: {} });
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_permission._id) {
          this.atualizar_permission();
        } else {
          this.criar_permission();
        }
      }
    }
  },
  created() {
    this.list_permissions();
  }
};
</script>

<style scoped>
.sheet-container {
  min-height: 95vh;
  max-height: 95vh;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
</style>
