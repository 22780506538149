<template>
  <v-list
    class="pa-0 ma-0 fonte"
    dense
    :dark="$theme.dark"
    :light="$theme.light"
    background-color="transparent"
    style="min-height: 20vh; max-height: 90vh; overflow: auto;"
  >
    <template v-for="(permission, i) in showFiltereds">
      <v-list-item
        @click="open(permission)"
        style="border-radius: 6px;"
        :key="permission._id"
        class="fonte pl-2 pr-1 animate__animated animate__fadeInUp"
      >
        <v-list-item-content class="">
          <v-list-item-title class="fonte fonteMini">
            {{ permission.nome }}
          </v-list-item-title>
          <v-list-item-subtitle class="fonteMini">
            <v-chip
              small
              :style="`color: ${$theme.primary}; border-radius: 3px;`"
            >
              Inícia em
              <span class="ml-2 fonte-bold">
                {{ permission.rota_inicial }}
              </span>
            </v-chip>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="fonteMini">
            Clique para gerenciar
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-avatar rounded size="36" color="#f2f2f2">
            <v-icon :color="$theme.primary">mdi-lock-outline</v-icon>
          </v-avatar>
        </v-list-item-action>
      </v-list-item>
      <v-divider :key="i"></v-divider>
    </template>
    <v-list-item
      style="min-height: 200px;"
      v-intersect="onIntersect"
    ></v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      itemsToShow: 12
    };
  },
  computed: {
    ...mapGetters(["get_permission"]),
    showFiltereds() {
      return this.filtereds.slice(0, this.itemsToShow);
    }
  },
  methods: {
    onIntersect() {
      this.itemsToShow += 12;
    },
    open(client) {
      this.$store.commit("set_permission", client);
    }
  }
};
</script>
